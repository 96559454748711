import "./style.scss";
import { Browser } from "@capacitor/browser";
import { IonCard, IonItem, IonLabel } from "@ionic/react";

interface Link {
  label: string;
  url: string;
}

export interface LearnMoreProps {
  links: Array<Link>;
}

export function LearnMoreCard(props: LearnMoreProps) {
  const { links } = props;

  return (
    <IonCard class="ion-padding-vertical" data-testid="ratings-learn-more-card">
      <IonItem lines="none">
        <h1 className="ratings-card-title">Learn More</h1>
      </IonItem>
      <>
        {links.map(({ label, url }) => (
          <IonItem
            key={label}
            class="ion-margin-bottom"
            button
            detail
            onClick={async () => {
              await Browser.open({ url });
            }}
          >
            <IonLabel>
              <h2 className="ratings-learn-more-label">{label}</h2>
            </IonLabel>
          </IonItem>
        ))}
      </>
    </IonCard>
  );
}
